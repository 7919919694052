import React, { useState } from "react";
import { logos, movieIcons } from "../../AppUtilities";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import * as API from "../../API/Index.js";
import moment from "moment";
const SlotBook = ({ formData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [liveData, setLiveData] = useState([]);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const navigate = useNavigate();

  console.log("liveData", liveData);

  const createSlug = (name) => {
    if (!name) {
      return ""; // Return an empty string if name is undefined or null
    }

    return name
      .toLowerCase() // Convert to lowercase
      .trim() // Remove any leading or trailing spaces
      .replace(/[\s\W-]+/g, "-") // Replace spaces or non-alphanumeric characters with dashes
      .replace(/^-+|-+$/g, ""); // Remove leading or trailing dashes
  };
  const slug = createSlug(formData?.name);

  const allMovieData = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const response = await API.getSchedule(
        localStorage.getItem("_userId"),
        header
      );
      setLiveData(response.data.data);
      console.log("response", response);
    } catch (error) {}
  };

  useEffect(() => {
    allMovieData();
    console.log("uissbsj", localStorage.getItem("_userId"));

    if (localStorage.getItem("_userId") === null) {
      navigate("/creator/sign-up");
    }
  }, []);

  return (
    <>
      <section class="movie_list slotBook">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-10 d-flex justify-content-between align-items-start">
              <h2 class="site_heading d-inline-block  mb-3">Live Now</h2>
              <span className="bookSlot btn btn-secondary2">
                <i class="fa-regular fa-clock"></i>{" "}
                <Link to="/event" className="text-light">
                  Book Your Slot
                </Link>
              </span>
            </div>
            <div class="col-lg-10">
              <div class="row">
                {liveData === undefined
                  ? "No Data Found"
                  : liveData.map((item, index) => (
                      <div class="col-lg-4 col-md-6 col-sm-6 mt-4">
                        <Link
                          target="_blank"
                          to={`https://stream.imboxocinema.com/${item?.channel_name}`}
                          class="critics"
                        >
                          {/* <img
                      src="https://ottadmin.imboxocinema.com/uploads/poster/1731408048452.jpg"
                      alt="pic"
                      class="w-100"
                    /> */}
                          <h3>{item?.channel_name}</h3>
                          <h5 class="mt-2 mb-2">
                            {moment(item?.scheduled_time).format(
                              "MMMM D, YYYY"
                            )}{" "}
                          </h5>
                          <span className="btn btn-danger">
                            <i class="fa-solid fa-tower-cell"></i> Live Now
                          </span>
                          {/* <span className="btn btn-info">
                        <i class="fa-solid fa-tower-cell"></i> Upcoming Streams
                      </span> */}
                        </Link>
                      </div>
                    ))}
              </div>
            </div>
            <div class="col-lg-10 d-flex justify-content-between align-items-start mt-5 d-none">
              <h2 class="site_heading d-inline-block  mb-3">
                Upcoming Streams
              </h2>
              {/* <span className="bookSlot btn btn-secondary2" onClick={openModal}>
                <i class="fa-regular fa-clock"></i> Book Your Slot
              </span> */}
            </div>
            <div class="col-lg-10 d-none">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 mt-4">
                  <Link to="/live-streaming-waiting-room" class="critics">
                    {/* <img
                      src="https://ottadmin.imboxocinema.com/uploads/poster/1731408048452.jpg"
                      alt="pic"
                      class="w-100"
                    /> */}
                    <h3>Justice League</h3>
                    <h5 class="mt-2 mb-2">June 8, 2024 - August 18, 2024</h5>
                    {/* <span className="btn btn-danger">
                      <i class="fa-solid fa-tower-cell"></i> Live Now
                    </span> */}
                    <span className="btn btn-info">
                      <i class="fa-solid fa-tower-cell"></i> join the lounge
                    </span>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 mt-4">
                  <Link to="/live-streaming-waiting-room" class="critics">
                    {/* <img
                      src="https://ottadmin.imboxocinema.com/uploads/poster/1731408048452.jpg"
                      alt="pic"
                      class="w-100"
                    /> */}
                    <h3>Justice League</h3>
                    <h5 class="mt-2 mb-2">June 8, 2024 - August 18, 2024</h5>
                    {/* <span className="btn btn-danger">
                      <i class="fa-solid fa-tower-cell"></i> Live Now
                    </span> */}
                    <span className="btn btn-info">
                      <i class="fa-solid fa-tower-cell"></i> join the lounge
                    </span>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 mt-4">
                  <Link to="/live-streaming-waiting-room" class="critics">
                    {/* <img
                      src="https://ottadmin.imboxocinema.com/uploads/poster/1731408048452.jpg"
                      alt="pic"
                      class="w-100"
                    /> */}
                    <h3>Justice League</h3>
                    <h5 class="mt-2 mb-2">June 8, 2024 - August 18, 2024</h5>
                    {/* <span className="btn btn-danger">
                      <i class="fa-solid fa-tower-cell"></i> Live Now
                    </span> */}
                    <span className="btn btn-info">
                      <i class="fa-solid fa-tower-cell"></i> join the lounge
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button onClick={closeModal} className="btn  close-modal">
              <i class="fa-regular fa-circle-xmark"></i>
            </button>

            <div className="row">
              <div className="col-md-6 bg1 d-flex flex-column align-items-center justify-content-center">
                <center>
                  <span className="movieIcons">
                    <img src={movieIcons} alt="" className="img-fluid " />
                  </span>
                </center>
                <h4 className="modalTitles">Please Book Your Slots</h4>
              </div>
              <div className="col-md-6 pl-0 bg2">
                <form action="" className="p-4 mt-4">
                  <div class="mb-3 form-group poster">
                    <label for="">
                      Poster <span class="text-danger">*</span>
                    </label>
                    <input type="file" name="avatar" accept="" />
                  </div>
                  <div class="mb-3 form-group">
                    <label htmlFor="">Event Title</label>
                    <input
                      type="text"
                      class="form-control"
                      id=""
                      placeholder="Event Title"
                    />
                  </div>
                  <div class="mb-3 form-group">
                    <label htmlFor="">Start date</label>
                    <input
                      type="date"
                      class="form-control custom-date-input"
                      id=""
                      placeholder="Start date"
                    />
                  </div>
                  <div class="mb-3 form-group">
                    <label htmlFor="">End date</label>
                    <input
                      type="date"
                      class="form-control custom-date-input"
                      id=""
                      placeholder="End date"
                    />
                  </div>
                  <div class="mb-3 form-group">
                    <button className="btn btn-danger">Submit Now</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SlotBook;
