import React from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import * as API from "../../API/Index.js";
const Donation = ({ formData }) => {
  const [amount, setAmount] = React.useState(10);
  const [selectedAmount, setSelectedAmount] = React.useState(10);

  const suggestedAmounts = [
    { value: 10, label: "$10.00" },
    { value: 20, label: "$20.00" },
    { value: 50, label: "$50.00" },
    { value: 100, label: "$100.00" },
  ];

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    setSelectedAmount(null);
  };

  const handleSuggestedAmount = async (value) => {
    setSelectedAmount(value);
    setAmount(value.toString());
  };

  const payMentSubmithandaler = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        user_id: localStorage.getItem("_userId"),
        amount: amount,
      };
      console.log("reqObj", reqObj);

      const response = await API.donation(reqObj, header);
      console.log("response", response);
      if (response.data.success === 1) {
        window.open(response.data.url, "_blank", "noopener,noreferrer");
      }
    } catch (error) {}
  };
  return (
    <>
      <div className="paymentMain">
        <div className="container">
          <div className="justify-content-center row">
            <div className="col-md-5">
              <blockquote>
                <h6>
                  Imboxo Pass{" "}
                  <span className="subcribed">
                    {formData?.payment_amount === "10" ? <>Subscribed</> : ""}
                  </span>
                  {formData?.payment_amount === "" ? (
                    ""
                  ) : formData?.payment_amount === "10" ? (
                    <>
                      <small className="paymentStatusTime d-flex justify-content-lg-end w-100">
                        {formData?.remaining_time}
                      </small>
                    </>
                  ) : (
                    ""
                  )}
                </h6>{" "}
                <h4>Price: $10</h4>{" "}
                <p>
                  Unlock unlimited access to all the content on Imboxo with no
                  restrictions on watchtime. Enjoy every movie, series, and show
                  at your own pace, anytime, anywhere!
                </p>
                <ul className="row">
                  <li className="col-md-6">👉 Unlimited streaming</li>
                  <li className="col-md-6">👉 Access to all titles </li>
                  <li className="col-md-6">👉 No time limits</li>
                </ul>
                <p className="text-info ">
                  <strong>
                    Best for binge-watchers and all-access entertainment
                    seekers!
                  </strong>
                </p>
                <p>
                  <strong>
                    Get the Imboxo Pass and dive into endless entertainment
                    today!
                  </strong>
                </p>
              </blockquote>
              <blockquote>
                <h6 className="mb-4">
                  {" "}
                  Hourly Streaming Model{" "}
                  <span className="subcribed">
                    {formData?.payment_amount === "" ? (
                      ""
                    ) : Number(formData?.payment_amount) < 10 ? (
                      <>Subscribed</>
                    ) : (
                      ""
                    )}
                  </span>
                  {formData?.payment_amount === "" ? (
                    ""
                  ) : Number(formData?.payment_amount) < 10 ? (
                    <>
                      <small className="paymentStatusTime d-flex justify-content-lg-end w-100">
                        {formData?.remaining_time}
                      </small>
                    </>
                  ) : (
                    <></>
                  )}
                </h6>{" "}
                <h4>Price: $1 to $9 (Pay As You Go)</h4>
                <p>
                  Not ready for unlimited streaming? With our hourly model, you
                  can watch for as long as you pay:
                </p>
                <strong>$1 = 1 hour of watchtime</strong>
                <br />
                <strong> $9 = 9 hours of watchtime</strong>
                <ul class="row">
                  <li className="col-md-6">👉 Unlimited streaming</li>
                  <li className="col-md-6">👉 Access to all titles </li>
                  <li className="col-md-6">👉 No time limits</li>
                </ul>
                <p>
                  When your time runs out, simply reload with more hours or
                  upgrade to the Imboxo Pass for unlimited access.
                </p>
                <p className="text-info ">
                  <strong>
                    Perfect for casual viewers who want flexibility and control
                    over their watchtime!
                  </strong>
                </p>
                <p>
                  Choose the Hourly Model to pay only for the hours you need!
                </p>
              </blockquote>

              <blockquote>
                <h6>💡 Switch Anytime!</h6>
                <p>
                  Start with the plan that fits your lifestyle, and you can
                  upgrade or adjust your plan anytime. Imboxo offers you the
                  freedom to watch your way.
                </p>
                <p className="text-info ">
                  <strong>
                    🎬 Ready to start? Select your plan and enjoy your first
                    watch today!
                  </strong>
                </p>
              </blockquote>
            </div>
            <div className="col-md-6 relative">
              <div class="sidebar-item">
                <div class="make-me-sticky">
                  <div className="bgBox">
                    <h2 className="loginHeading">Enter Amount</h2>
                    <p className="text-light ">
                      Support Creativity, Empower Filmmakers By choosing the
                      Imboxo Pass, you’re not just unlocking unlimited
                      entertainment—you’re also supporting talented filmmakers
                      and creators on our platform. Your subscription helps us
                      bring more diverse stories to life and empowers the
                      creative community. 💡 Want to make a bigger impact? Feel
                      free to donate more when subscribing to the Imboxo Pass.
                      Every extra dollar goes directly to supporting independent
                      filmmakers and fostering unique content creation. Your
                      contribution matters. Together, let’s champion creativity
                      and storytelling!
                    </p>
                    <div class="ms_register_form">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={amount}
                          onChange={handleAmountChange}
                          placeholder="Amount"
                        />
                      </div>
                      <div className="row g-2 mb-3">
                        {suggestedAmounts.map((item, index) => (
                          <div className="col-3" key={index}>
                            <button
                              className={`btn btn-secondary2 w-100 ${
                                selectedAmount === item.value ? "activePay" : ""
                              }`}
                              onClick={() => handleSuggestedAmount(item.value)}
                            >
                              ${item.value}.00
                            </button>
                          </div>
                        ))}
                      </div>
                      <button
                        onClick={payMentSubmithandaler}
                        className="btn btn-secondary2 w-100 activePay"
                      >
                        Support ${selectedAmount || Number(amount)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Donation;
