import React, { useState } from "react";
import { logos, users } from "../AppUtilities";
import { Link, NavLink } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { AVTER, IMG, NOIMG } from "../API/constant";
const Header = ({ setIsuser, isLogin, formData }) => {
  const [isOpen, setIsOpen] = useState(false);
  console.log("formData", formData);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <header>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-12 col-sm-11 d-flex justify-content-between align-items-center">
              <span class="logo">
                <Link to="/home">
                  <img src={logos} alt="" class="img-fluid" />
                </Link>
              </span>
              <div className="d-flex align-items-center">
                <nav>
                  <div
                    class="hamburger"
                    id="hamburger-1"
                    onClick={toggleDrawer}
                  >
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                  </div>
                  <ul>
                    <li>
                      <NavLink to="/home">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to="/movie-list">
                        {" "}
                        Explore <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <small></small>
                            <ul>
                              <li>
                                <NavLink to="#">
                                  <h6>By Country</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>By Director</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>By Studio</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>By Year</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>New Releases</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Trending</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Recommended for You</h6>
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li class="col-sm-4 col-md-3">
                            <small>By Genre</small>
                            <ul>
                              <li>
                                <NavLink to="#">Action</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Drama</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Comedy</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Documentary</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Horror</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Sci-Fi</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Romance</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Thriller</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Animation</NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <NavLink to="https://donorbox.org/imboxo-filmmaking-community">
                        Donate
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/live">
                        {" "}
                        Live <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <ul>
                              <li>
                                <NavLink to="#">
                                  <h6>Upcoming Events</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Live Now</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Past Streams</h6>
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <NavLink to="https://imboxo.shop/">Shop</NavLink>
                    </li>
                    <li>
                      <NavLink to="/festival">
                        {" "}
                        Festivals <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <ul>
                              <li>
                                <NavLink to="#">
                                  <h6>Current Festivals</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Awards & Winners</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Past Festivals</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Submit Your Film</h6>
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <NavLink to="/filmmaker">
                        {" "}
                        Filmmakers <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>

                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <ul>
                              <li>
                                <NavLink to="#">
                                  <h6>Filmmaker Portal</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Resources & Tools</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Success Stories</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Interviews</h6>
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <NavLink to="/community">
                        {" "}
                        Community <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <ul>
                              <li>
                                <NavLink to="#">
                                  <h6>Discussions</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Watch Parties</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>User Reviews</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Social Media</h6>
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <NavLink to="/about">
                        {" "}
                        About Us <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <small>About Us</small>
                            <ul>
                              <li>
                                <a href="#mission">
                                  <h6>Our Purpose</h6>
                                </a>
                              </li>
                              <li>
                                <a href="#team">
                                  <h6>The Team</h6>
                                </a>
                              </li>
                              <li>
                                <a href="#career">
                                  <h6>Careers</h6>
                                </a>
                              </li>
                              <li>
                                <a href="#press">
                                  <h6>Press Releases</h6>
                                </a>
                              </li>
                              <li>
                                <a href="#contact">
                                  <h6>Contact Us</h6>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {/* <li>
                      <NavLink to="#">Plan <i class="fa-solid fa-chevron-down"></i></NavLink>

                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                              <small>Plan</small>
                              <ul>
                                <li><NavLink to="/plans"><h6>Subscription Plans</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Pay-Per-View Options</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Shop & Watch</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Free Tickets</h6></NavLink></li>
                              </ul>
                          </li>
                        </ul>
                      </div>
                    </li> */}

                    {/* <li>
                      <NavLink to="/">Creators</NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink to="/"> Shop <i class="fa-solid fa-chevron-down"></i></NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                              <small>Shop</small>
                              <ul>
                                <li><NavLink to="#"><h6>Merchandise</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Posters & Art</h6></NavLink></li>
                                <li><NavLink to="#"><h6>DVDs & Blu-rays</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Digital Downloads</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Exclusive Content</h6></NavLink></li>
                              </ul>
                          </li>
                        </ul>
                      </div>
                    </li> */}

                    {/* <li>
                      <NavLink to="/Introduction">Series</NavLink>
                    </li>
                    <li>
                      <NavLink to="/dd">My List</NavLink>
                    </li> */}
                  </ul>
                </nav>
                <ul class="infos">
                  <li>
                    <NavLink to="#">
                      {/* <i class="bi bi-search"></i> */}
                    </NavLink>
                  </li>
                  {formData === undefined || isLogin === undefined ? (
                    <>
                      <li>
                        <Link
                          to="/creator/sign-up"
                          className="btn btn-secondary2 becomebtn"
                        >
                          Become a creator
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {formData === undefined || isLogin === undefined ? (
                    <li className="ml-10">
                      <Link
                        to="/login"
                        className="btn btn-secondary2 becomebtn"
                      >
                        Login
                      </Link>
                    </li>
                  ) : (
                    <li class="dropdown useRs">
                      <Link
                        to={isLogin ? "javascript:void(0)" : "/login"}
                        class="btn btn-primary dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <img
                          src={
                            formData.profile_img === "" ||
                            formData.profile_img === undefined ||
                            formData.profile_img === null
                              ? AVTER
                              : formData.profile_img
                          }
                          className="userthumb"
                        />
                      </Link>
                      <ul class="dropdown-menu">
                        <li>
                          <NavLink
                            to={isLogin ? "/set-profile" : "/login"}
                            class="dropdown-item"
                          >
                            <i class="fa-regular fa-user"></i> Profile
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#" class="dropdown-item">
                            <i class="fa-solid fa-gear"></i> Settings
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#" class="dropdown-item">
                            <i class="fa-solid fa-eye"></i> Watchlist
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#" class="dropdown-item">
                            <i class="fa-solid fa-list-check"></i> Viewing
                            History
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/subscription" class="dropdown-item">
                            <i class="fa-regular fa-bell"></i> Subscription
                            Details
                          </NavLink>
                        </li>
                      </ul>
                    </li>

                    // <li className="useRs">
                    //   <Link to={isLogin ? "javascript:void(0)" : "/login"}>
                    //     <img
                    //       src={
                    //         formData.profile_img === "" ||
                    //         formData.profile_img === undefined ||
                    //         formData.profile_img === null
                    //           ? AVTER
                    //           : IMG + formData.profile_img
                    //       }
                    //       className="userthumb"
                    //     />
                    //   </Link>
                    //   <ul className="cusTomDropdown">
                    //     <li>
                    //       <NavLink to={isLogin ? "/set-profile" : "/login"}>
                    //         <i class="fa-regular fa-user"></i> Profile
                    //       </NavLink>
                    //     </li>
                    //     <li>
                    //       <NavLink to="#">
                    //         <i class="fa-solid fa-gear"></i> Settings
                    //       </NavLink>
                    //     </li>
                    //     <li>
                    //       <NavLink to="#">
                    //         <i class="fa-solid fa-eye"></i> Watchlist
                    //       </NavLink>
                    //     </li>
                    //     <li>
                    //       <NavLink to="#">
                    //         <i class="fa-solid fa-list-check"></i> Viewing
                    //         History
                    //       </NavLink>
                    //     </li>
                    //     <li>
                    //       <NavLink to="/subscription">
                    //         <i class="fa-regular fa-bell"></i> Subscription
                    //         Details
                    //       </NavLink>
                    //     </li>
                    //   </ul>
                    // </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="mobileNav"
      >
        <div>
          <div className="d-flex flex-column justify-content-end">
            <div className="d-flex justify-content-between align-items-center pl-5">
              {formData === undefined || isLogin === undefined ? (
                <>
                  <span>
                    <Link
                      to="/creator/sign-up"
                      className="btn btn-secondary2 becomebtn"
                    >
                      Become a creator
                    </Link>
                  </span>
                </>
              ) : (
                <></>
              )}

              <span onClick={() => setIsOpen(false)} className="closeNav">
                <i class="fa-solid fa-xmark"></i>
              </span>
            </div>

            <Accordion.Root type="single" collapsible>
              <Accordion.Item value="item-1">
                <Accordion.Header>
                  <Accordion.Trigger>
                    <Link to="/home"> Home</Link>
                  </Accordion.Trigger>
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item value="item-2">
                <Accordion.Header>
                  <Accordion.Trigger>
                    <Link to="/movie-list">
                      {" "}
                      Explore
                      <ChevronDownIcon
                        className="AccordionChevron"
                        aria-hidden
                      />
                    </Link>
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                  <ul class="row">
                    <li class="col-sm-12">
                      <small></small>
                      <ul>
                        <li>
                          <NavLink to="#">
                            <h6>By Country</h6>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <h6>By Director</h6>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <h6>By Studio</h6>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <h6>By Year</h6>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <h6>New Releases</h6>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <h6>Trending</h6>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <h6>Recommended for You</h6>
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li class="col-sm-12">
                      <small>By Genre</small>
                      <ul>
                        <li>
                          <NavLink to="#">Action</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">Drama</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">Comedy</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">Documentary</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">Horror</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">Sci-Fi</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">Romance</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">Thriller</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">Animation</NavLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item value="item-3">
                <Accordion.Header>
                  <Accordion.Trigger>
                    <Link to="https://donorbox.org/imboxo-filmmaking-community">
                      {" "}
                      Donate
                    </Link>
                  </Accordion.Trigger>
                </Accordion.Header>
                {/* <Accordion.Content>
                  <ul>
                    <li>
                      <a href="">Upcoming Events</a>
                    </li>
                    <li>
                      <a href="">Live Now</a>
                    </li>
                    <li>
                      <a href="">Past Streams</a>
                    </li>
                  </ul>
                </Accordion.Content> */}
              </Accordion.Item>
              <Accordion.Item value="item-3">
                <Accordion.Header>
                  <Accordion.Trigger>
                    live
                    <ChevronDownIcon className="AccordionChevron" aria-hidden />
                  </Accordion.Trigger>
                </Accordion.Header>
                {/* <Accordion.Content>
                  <ul>
                    <li>
                      <a href="">Upcoming Events</a>
                    </li>
                    <li>
                      <a href="">Live Now</a>
                    </li>
                    <li>
                      <a href="">Past Streams</a>
                    </li>
                  </ul>
                </Accordion.Content> */}
              </Accordion.Item>
              <Accordion.Item value="item-4">
                <Accordion.Header>
                  <Accordion.Trigger>
                    <Link to="https://imboxo.shop/" target="_blank">
                      Shop
                    </Link>
                    <ChevronDownIcon className="AccordionChevron" aria-hidden />
                  </Accordion.Trigger>
                </Accordion.Header>
                {/* <Accordion.Content>
                  <ul>
                    <li>
                      <a href="">Current Festivals</a>
                    </li>
                    <li>
                      <a href="">Past Festivals</a>
                    </li>
                    <li>
                      <a href="">Submit Your Film</a>
                    </li>
                     <li>
                      <a href="">Awards & Winners</a>
                    </li>
                  </ul>
                </Accordion.Content> */}
              </Accordion.Item>
              <Accordion.Item value="item-5">
                <Accordion.Header>
                  <Accordion.Trigger>
                    <Link to="/filmmaker">
                      {" "}
                      Filmmakers
                      <ChevronDownIcon
                        className="AccordionChevron"
                        aria-hidden
                      />
                    </Link>
                  </Accordion.Trigger>
                </Accordion.Header>
                {/* <Accordion.Content>
                  <ul>
                    <li>
                      <a href="">Filmmaker Portal</a>
                    </li>
                    <li>
                      <a href="">Success Stories</a>
                    </li>
                    <li>
                      <a href="">Interviews</a>
                    </li>
                     <li>
                      <a href="">Resources & Tools</a>
                    </li>
                  </ul>
                </Accordion.Content> */}
              </Accordion.Item>
              <Accordion.Item value="item-6">
                <Accordion.Header>
                  <Accordion.Trigger>
                    <Link to="/community">
                      {" "}
                      Community
                      <ChevronDownIcon
                        className="AccordionChevron"
                        aria-hidden
                      />
                    </Link>
                  </Accordion.Trigger>
                </Accordion.Header>
                {/* <Accordion.Content>
                  <ul>
                    <li>
                      <a href="">Discussions</a>
                    </li>
                    <li>
                      <a href="">User Reviews</a>
                    </li>
                    <li>
                      <a href="">Social Media</a>
                    </li>
                     <li>
                      <a href="">Watch Parties</a>
                    </li>
                  </ul>
                </Accordion.Content> */}
              </Accordion.Item>
              <Accordion.Item value="item-7">
                <Accordion.Header>
                  <Accordion.Trigger>
                    <Link to="/about">
                      {" "}
                      About Us
                      <ChevronDownIcon
                        className="AccordionChevron"
                        aria-hidden
                      />
                    </Link>
                  </Accordion.Trigger>
                </Accordion.Header>

                {/* <Accordion.Content>
                  <ul>
                    <li>
                      <a href=""> Our Purpose</a>
                    </li>
                    <li>
                      <a href="">The Team</a>
                    </li>
                    <li>
                      <a href="">Careers</a>
                    </li>
                    <li>
                      <a href="">Press Releases</a>
                    </li>
                     <li>
                      <a href="">Contact Us</a>
                    </li>
                  </ul>
                </Accordion.Content> */}
              </Accordion.Item>
              <Accordion.Item value="item-8">
                <Accordion.Header>
                  <Accordion.Trigger>
                    <Link to="/festival">
                      {" "}
                      Festivals
                      <ChevronDownIcon
                        className="AccordionChevron"
                        aria-hidden
                      />
                    </Link>
                  </Accordion.Trigger>
                </Accordion.Header>

                {/* <Accordion.Content>
                  <ul>
                    <li>
                      <a href=""> Our Purpose</a>
                    </li>
                    <li>
                      <a href="">The Team</a>
                    </li>
                    <li>
                      <a href="">Careers</a>
                    </li>
                    <li>
                      <a href="">Press Releases</a>
                    </li>
                     <li>
                      <a href="">Contact Us</a>
                    </li>
                  </ul>
                </Accordion.Content> */}
              </Accordion.Item>
            </Accordion.Root>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
