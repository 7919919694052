import React from "react";
import {
  makeStyles,
  Slider,
  withStyles,
  Button,
  Tooltip,
  Popover,
  Grid,
} from "@material-ui/core";
import {
  FastForward,
  FastRewind,
  Pause,
  PlayArrow,
  SkipNext,
  VolumeUp,
  VolumeOff,
  Watch,
  TimerOutlined,
  Fullscreen,
} from "@material-ui/icons";
import { pipicon } from "../../AppUtilities";
import { ClipLoader } from "react-spinners";
import { useLocation } from "react-router";
import io from "socket.io-client";
import { SURL } from "../../API/constant";

const useStyles = makeStyles({
  volumeSlider: {
    width: "100px",
    color: "#9556CC",
  },

  bottomIcons: {
    color: "#999",
    padding: "12px 8px",

    "&:hover": {
      color: "#fff",
    },
  },
});

const PrettoSlider = withStyles({
  root: {
    height: "20px",
    color: "#9556CC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#9556CC",
    border: "2px solid currentColor",
    marginTop: -3,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 5,
    borderRadius: 4,
    width: "100%",
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);

const Control = ({
  onPlayPause,
  playing,
  onRewind,
  onForward,
  played,
  onSeek,
  onSeekMouseUp,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  volume,
  mute,
  onMute,
  duration,
  currentTime,
  onMouseSeekDown,
  controlRef,
  enablePiP,
  videoSpped,
  isOpen,
  videoPlayerSpeed,
  speedValue,
  buffer,
  toggleFullscreen,
  watchTime,
}) => {
  const location = useLocation();

  const socket = io(SURL, {
    reconnectionDelayMax: 10000, // Maximum delay before a reconnection attempt (in milliseconds)
    timeout: 20000, // Socket connection timeout (in milliseconds)
    reconnectionAttempts: Infinity, // Number of reconnection attempts before giving up
  });
  // console.log("currentTime", currentTime);

  const classes = useStyles();
  console.log("watchTime", watchTime);

  socket.emit("WATCH_TIME_UPDATE", {
    user_id: localStorage.getItem("_userId"),
    movie_id: location?.state?.id,
    total_watch_time: watchTime,
  });

  socket.on("WATCH_TIME_UPDATE_SUCCESS", (data) => {
    console.log("data", data);
  });
  return (
    <div className="control_Container" ref={controlRef}>
      <div className="mid__container">
        {buffer && (
          <p>
            <ClipLoader size={50} color="#fff" />{" "}
          </p>
        )}
        {/* <div className="icon__btn" onDoubleClick={onRewind}>
          <FastRewind fontSize="medium" />
        </div>

        <div className="icon__btn" onClick={onPlayPause}>
          {playing ? (
            <Pause fontSize="medium" />
          ) : (
            <PlayArrow fontSize="medium" />
          )}
        </div>

        <div className="icon__btn">
          <FastForward fontSize="medium" onDoubleClick={onForward} />
        </div> */}
      </div>
      <div className="bottom__container">
        <div className="slider__container">
          <PrettoSlider
            min={0}
            max={100}
            value={played * 100}
            onChange={onSeek}
            onChangeCommitted={onSeekMouseUp}
            onMouseDown={onMouseSeekDown}
          />
        </div>
        <div className="control__box">
          <div className="inner__controls">
            {buffer ? (
              <p>
                <ClipLoader size={35} color="#fff" />{" "}
              </p>
            ) : (
              <div className="icon__btn playPuse" onClick={onPlayPause}>
                {playing ? (
                  <Pause fontSize="medium" color="#fff" />
                ) : (
                  <PlayArrow fontSize="medium" color="#fff" />
                )}{" "}
              </div>
            )}

            {/* <div className="icon__btn">
              <SkipNext fontSize="medium" color="#fff" />
            </div> */}

            <div className="icon__btn contIcon" onClick={onMute}>
              {mute ? (
                <VolumeOff fontSize="medium" />
              ) : (
                <VolumeUp fontSize="medium" />
              )}
              <div className="volime">
                <Slider
                  className={`${classes.volumeSlider}`}
                  onChange={onVolumeChangeHandler}
                  value={volume * 100}
                  onChangeCommitted={onVolumeSeekUp}
                />
              </div>
            </div>

            {buffer ? (
              ""
            ) : (
              <span className="timeSecr">
                {currentTime} / {""}
                {duration.hours === 0 ? (
                  <>
                    {duration.minutes} : {duration.seconds.toFixed()}
                  </>
                ) : (
                  <>
                    {duration.hours} :{" "}
                    {duration.minutes < 10
                      ? "0" + duration.minutes
                      : duration.minutes}{" "}
                    : {""}
                    {duration.seconds.toFixed()}
                  </>
                )}
              </span>
            )}
          </div>
          <div className="genarelSettig">
            {isOpen ? (
              <div className="drowpDown">
                <ul>
                  <li onClick={() => speedValue("1.0")}>Normal</li>
                  <li onClick={() => speedValue("1.5")}>1.5</li>
                  <li onClick={() => speedValue("2")}>2</li>
                </ul>
              </div>
            ) : (
              ""
            )}
            <span className="speedValue">
              {" "}
              {videoSpped === "1.0" ? "Normal" : videoSpped}
            </span>
            <div className="watchIcon" onClick={videoPlayerSpeed}>
              <TimerOutlined />
            </div>

            <Button onClick={enablePiP}>
              <img src={pipicon} />
            </Button>
            <Button onClick={toggleFullscreen}>
              <Fullscreen />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Control;
