import React from "react";
import Film from "../../assets/images/community/1.jpg";

const LiveStreamingWaitingRoom = () => {
  return (
    <>
      <section className="about innerpage rooms">
        <div className="blueBG">
          <center>
            <h5>Welcome to the Movie Live Streaming Waiting Room!</h5>
            <h3 className="mt-3 mb-3">The Show Begins Soon!</h3>
            <p>
              <em>
                Get ready for an unforgettable cinematic experience! Our live
                stream will begin shortly. While you wait, immerse yourself in
                the world of our upcoming feature.
              </em>
            </p>
            <span>
              <a href="#" class="btn btn-info mb-4">
                Join the Movie Lounge
              </a>
            </span>
          </center>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-center mt-4 mb-4">
                <div className="col-sm-4">
                  <div className="grayBox">
                    <h4>About the Upcoming Stream:</h4>
                    <ul className="nolist">
                      <li>🎥 Movie Title: TRIKAALAM</li>{" "}
                      <li>📅 Streaming Date: 25-12-2024</li>
                      <li>⏰ Start Time: 10:45 am</li>
                      <li>🌟 Genre: STANY JOYSON</li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="grayBox">
                    <h4>Meet the Cast & Crew:</h4>
                    <ul className="nolist">
                      <li>🎥 Director: STANY JOYSON</li>{" "}
                      <li>🌟 Leading Stars: STANY JOYSON</li>
                      <li>🌟 Special Appearances: STANY JOYSON</li>
                    </ul>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center mt-4">
                  <div className="col-sm-3">
                    <img src={Film} alt="" className="img-fluid borders" />
                  </div>
                  <div className="col-sm-5">
                    <h5 className="mb-3">Storyline Sneak Peek</h5>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Tenetur numquam rerum ad, officiis ex consequatur alias
                      voluptatum dolor provident natus maiores sequi minus
                      neque, id totam veritatis placeat animi minima. Lorem
                      ipsum dolor sit amet consectetur adipisicing elit.
                      Exercitationem eius obcaecati animi necessitatibus aperiam
                      rem omnis asperiores quo commodi impedit molestias ea,
                      natus error quos perspiciatis qui, unde corrupti id.
                      <br />
                      <br /> Lorem ipsum dolor sit amet consectetur, adipisicing
                      elit. Voluptate consectetur est laborum odio sequi quam
                      doloribus qui corrupti optio iste ullam numquam eum, aut
                      modi quos ex cupiditate inventore? Similique?
                    </p>

                    <h5 className="mb-3">Stay Tuned:</h5>
                    <p>
                      The live stream will start automatically. Make sure your
                      device is connected and your snacks are ready!
                      <br />
                      <strong>
                        Thank you for being here. See you at the premiere!
                      </strong>
                    </p>
                  </div>
                  <div className="col-sm-8 mt-5">
                    <div className="blueBox">
                      <h5 className="mb-3">Connect with Fellow Fans:</h5>
                      <p>
                        While you wait, join the conversation with other movie
                        lovers! Share your excitement, predictions, and favorite
                        movie moments.
                      </p>
                      <span>
                        <a href="#" class="btn btn-info mb-4">
                          Join the Movie Lounge
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LiveStreamingWaitingRoom;
