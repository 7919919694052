import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { latestSliders } from "../../../AppUtilities";
import { IMG, NOIMG } from "../../../API/constant";
import { Link, useParams } from "react-router-dom";
import * as API from "../../../API/Index.js";
const SimilerPartSlider = ({ onRefreshPage }) => {
  const params = useParams();

  const [allData, setAllData] = useState([]);
  const allMovieData = async () => {
    try {
      const response = await API.getMovie();
      // console.log("response", response);
      setAllData(response.data.data);
    } catch (error) {}
  };
  //console.log("allData", allData);
  useEffect(() => {
    allMovieData();
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
    },
    tabletlandscape: {
      breakpoint: { max: 767, min: 667 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={true}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        center={true}
      >
        {allData.length === 0 ? (
          <></>
        ) : (
          allData.map((item, index) => (
            <div>
              <div class="item" key={index}>
                <Link
                  onClick={() => onRefreshPage(item.slug)}
                  to={`/movie-details/${item.slug}`}
                >
                  <img
                    src={item.poster === null ? NOIMG : item.poster}
                    alt=""
                    class="w-100 main_img"
                  />
                  <div class="week_content">
                    <center>
                      <h4>{item.name}</h4>
                    </center>
                  </div>
                </Link>
              </div>
            </div>
          ))
        )}
      </Carousel>
    </>
  );
};

export default SimilerPartSlider;
