import React from "react";
import Film from "../../assets/images/community/1.jpg";
import Film2 from "../../assets/images//community/2.jpg";
import Film3 from "../../assets/images//community/3.jpg";
import Film4 from "../../assets/images//community/4.jpg";
import Film5 from "../../assets/images//community/5.jpg";
import Globe from "../../assets/images/about/17.png";
import Idea from "../../assets/images/about/18.png";
import Movie from "../../assets/images/about/16.png";
import Mind from "../../assets/images/about/19.png";
import Smile from "../../assets/images/about/11.png";
import Growth from "../../assets/images/about/20.png";
import Logo from "../../assets/images/about/logo.png";
import { Link } from "react-router-dom";
const Community = () => {
  return (
    <>
      <section className="relative patterns film mt-8 resFilm">
        <div class="container-fluid p-0">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-4 p-0">
              <img src={Film} alt="" className="w-100 extraheight" />
            </div>
            <div class="col-lg-8 col-md-8 p-5">
              <h2 className="heads mb-3">What We Stand For </h2>
              <h4>Building Bridges Through Storytelling</h4>
              <ul className=" mb-4">
                <li>
                  <span>
                    <img src={Globe} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <p>
                      At IMBOXO, we believe storytelling is a universal language
                      connecting us all. Our community is a global hub for
                      Immakers, audiences, and industry professionals passionate
                      about independent cinema.
                    </p>
                  </div>
                </li>
                <li>
                  <span>
                    <img src={Idea} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <p>
                      This is a space for sharing ideas, nding collaborators,
                      and celebrating diverse _O_ perspectives.
                    </p>
                  </div>
                </li>
              </ul>
              <h4>Our Mission</h4>
              <ul className=" mb-4">
                <li>
                  <span>
                    <img src={Mind} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <p>
                      To create a safe and inclusive space where creative minds
                      can meet, inspire, and ® thrive
                    </p>
                  </div>
                </li>
                <li>
                  <span>
                    <img src={Movie} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <p>
                      To foster collaboration across borders, cultures, and
                      genres
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="relative patterns film">
        <div class="container-fluid p-0">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-4  p-0">
              <img src={Film2} alt="" className="w-100 extraheight" />
            </div>
            <div class="col-lg-8 col-md-8  p-5">
              <h2 className="heads mb-3">Features of the Community</h2>
              <h4>Collaborate and Create</h4>
              <ul className=" mb-4">
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Join a thriving network of like-minded individuals. Work
                      on projects, exchange ideas, and push the boundaries of
                      storytelling together.
                    </p>
                  </div>
                </li>
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Access exclusive discussion boards, forums, and virtual
                      events.
                    </p>
                  </div>
                </li>
              </ul>
              <h4>Mentorship Opportunities </h4>
              <ul className=" mb-4">
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Connect with seasoned Immakers and industry veterans
                      willing to guide emerging talent.
                    </p>
                  </div>
                </li>
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Find and offer advice on Immaking techniques, funding
                      opportunities, and marketing strategies.
                    </p>
                  </div>
                </li>
              </ul>
              <h4>Global Conversations </h4>
              <ul className=" mb-4">
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Break down barriers with live Q&A sessions, panel
                      discussions, and meet-and-greets.
                    </p>
                  </div>
                </li>
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Engage in meaningful dialogue that spans cultures and
                      perspectives
                    </p>
                  </div>
                </li>
              </ul>
              <h4>Celebrate Achievements </h4>
              <ul className=" mb-4">
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Acknowledge and amplify the success of our members through
                      shoutouts, features, and curated showcases.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="relative patterns film">
        <div class="container-fluid p-0">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-4 p-0">
              <img src={Film3} alt="" className="w-100 extraheight" />
            </div>
            <div class="col-lg-8 col-md-8 p-5">
              <h2 className="heads mb-3">Why Join the IMBOXO Community? </h2>
              <h4>Be Part of Something Bigger </h4>
              <ul className=" mb-4">
                <li>
                  <span>
                    <img src={Movie} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <p>
                      IMBOXO isn't just a platform—it's a movement to redene
                      independent cinema.
                    </p>
                  </div>
                </li>
                <li>
                  <span>
                    <img src={Smile} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <p>
                      Collaborate with people who share your vision and drive to
                      create impactful Ims.
                    </p>
                  </div>
                </li>
              </ul>
              <h4>Inspiration at Every Turn</h4>
              <ul className=" mb-4">
                <li>
                  <span>
                    <img src={Globe} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <p>
                      Stay inspired by stories from around the world. Our
                      community is a wellspring of creativity
                    </p>
                  </div>
                </li>
              </ul>
              <h4>Shared Success, Shared Growth</h4>
              <ul className=" mb-4">
                <li>
                  <span className="">
                    <img src={Growth} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <p>
                      Whether you're a filmmaker, producer, or fan, your voice
                      contributes to the collective 1 success of independent
                      cinema.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="relative patterns film">
        <div class="container-fluid p-0">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-4 p-0">
              <img src={Film4} alt="" className="w-100 extraheight" />
            </div>
            <div class="col-lg-8 col-md-8 p-5">
              <h2 className="heads mb-3">How to Get Involved</h2>
              <h4>Join the Conversation </h4>
              <ul className=" mb-4">
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Sign up for access to our forums and start connecting with
                      others who share your passion.
                    </p>
                  </div>
                </li>
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Participate in live events and workshops designed to
                      ignite collaboration.
                    </p>
                  </div>
                </li>
              </ul>
              <h4>Take the Lead </h4>
              <ul className=" mb-4">
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Start your own discussions, share resources, or create a
                      project group within the community.
                    </p>
                  </div>
                </li>
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Help shape the future of cinema by mentoring new creators.
                    </p>
                  </div>
                </li>
              </ul>
              <h4>Celebrate Together </h4>
              <ul className=" mb-4">
                <li>
                  <span className="checkmarks">
                    <i class="fa-solid fa-check"></i>
                  </span>
                  <div>
                    <p>
                      Showcase your work to a supportive audience, get feedback,
                      and grow as an artist.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="relative patterns film">
        <div class="container-fluid p-0">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-4  p-0">
              <img src={Film5} alt="" className="w-100 extraheight" />
            </div>
            <div class="col-lg-8 col-md-8  p-5">
              <h2 className="heads mb-3">Your Story Starts Here </h2>
              <ul className="mt-5 mb-4">
                <li>
                  <span>
                    <img src={Logo} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <p>
                      Join IMBOXO today and become part of a global family
                      dedicated to shaping the future of cinema.
                    </p>
                  </div>
                </li>
              </ul>
              {/* <span>
                <Link
                  to="https://www.reddit.com/user/IMBOXO_Official/"
                  target="_blank"
                  class="btn btn-secondary2 mb-4"
                >
                  IMBOXO Reddit
                </Link>
              </span> */}
              <span>
                <Link
                  to="https://discord.com/channels/1309074912551501945/1309074914426490941"
                  target="_blank"
                  class="btn btn-secondary2 mb-4"
                >
                  IMBOXO Discord
                </Link>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Community;
