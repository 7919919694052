import React, { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import * as API from "../../API/Index.js";
import { Link } from "react-router-dom";
import { IMG } from "../../API/constant.js";
const MovieDetailsModal = ({
  open,
  onCloseModal,
  individualMoviles,
  videoFormat,
}) => {
  const [isMovie, setIsMovie] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [genreData, setGenreData] = useState([]);
  console.log("individualMoviles", individualMoviles);

  const videoRefM = useRef(null);

  const [isMutedM, setIsMutedM] = useState("");

  const toggleMuteM = () => {
    if (videoRefM.current) {
      videoRefM.current.muted = !isMutedM;
      setIsMutedM(!isMutedM);
    }
  };

  // ?** Gener Data

  const genreDataShow = async (data, name, filterType) => {
    setIsMovie(true);
    setGenreData({
      id: data,
      name: name,
    });
    try {
      const reqObj = {
        value: [data],
        type: filterType,
      };
      console.log("reqObj", reqObj);
      const response = await API.getMoviebyFilter(reqObj);
      console.log("response", response);
      setFilterData(response.data.data);
    } catch (error) {}
  };

  const gernModal = () => {
    setIsMovie(false);
  };

  // ?*** SINGLE MOVIE DETAILS API

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        classNames={{ modal: "customModalClass" }}
        center
      >
        {isMovie ? (
          <>
            <div class="col-md-12">
              <button className="backButton" onClick={gernModal}>
                <i class="bi bi-arrow-left"></i>
              </button>
              <h4 className="text-center">{genreData.name}</h4>
              <div class="row">
                {filterData === ""
                  ? ""
                  : filterData.map((item, index) => (
                      <div class="col-md-3 col-sm-6 mt-4" key={index}>
                        <Link
                          to={`/movie-details/${item.slug}`}
                          className="relatedbox"
                        >
                          <img src={item.poster} alt="" class="w-100" />
                          <span>{item.name}</span>
                        </Link>
                      </div>
                    ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="videoWrap">
              <video
                autoPlay
                src={videoFormat}
                loop
                class="aboutVideo"
                poster={IMG + individualMoviles.poster}
                ref={videoRefM}
              />
              <div class="movieInfo">
                <div class="btngroup mt-4">
                  <span>
                    <Link
                      to={`/movie-details/${individualMoviles.slug}`}
                      class="btn banner_btn btn-light"
                    >
                      <i class="fa-solid fa-play"></i> Play
                    </Link>
                  </span>
                  <span onClick={toggleMuteM}>
                    {isMutedM ? (
                      <i class="fa-solid fa-volume-xmark"></i>
                    ) : (
                      <i class="fa-solid fa-volume-high"></i>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div class="row modalText">
              <div class="col-md-7">
                <ul class="d-flex">
                  {/* <li className="text-success">
                <strong>88% Match</strong>
              </li>
              <li>2024</li> */}
                  <li>{individualMoviles.duration}</li>
                </ul>
                <p>
                  <i class="fa-solid fa-language"></i> Language,{" "}
                  {individualMoviles === ""
                    ? ""
                    : individualMoviles.language.map((item, index) => (
                        <>{item.name}</>
                      ))}
                </p>

                <div className="summary">
                  <p>{individualMoviles.description}</p>
                </div>
              </div>

              <div class="col-md-5 rightText">
                <p>
                  <span>Crew :</span>{" "}
                  {individualMoviles === ""
                    ? ""
                    : individualMoviles.crew.map((item, index) => (
                        <Link
                          to="#"
                          onClick={() =>
                            genreDataShow(item.id, item.name, "crew")
                          }
                        >
                          {item.name}{" "}
                          {index < individualMoviles.cast.length - 1
                            ? ", "
                            : ""}
                        </Link>
                      ))}
                </p>
                <p>
                  <span>Genres :</span>{" "}
                  {individualMoviles === ""
                    ? ""
                    : individualMoviles.genre.map((item, index) => (
                        <Link
                          to="#"
                          onClick={() =>
                            genreDataShow(item.id, item.name, "genre")
                          }
                        >
                          {item.name}{" "}
                          {index < individualMoviles.genre.length - 1
                            ? ", "
                            : ""}
                        </Link>
                      ))}
                </p>
              </div>

              <div class="col-md-12 mt-4">
                <h4>Related Movies</h4>
                <div class="row">
                  {individualMoviles === ""
                    ? ""
                    : individualMoviles.related_movies.map((item, index) => (
                        <div class="col-md-3 col-sm-6 mt-4" key={index}>
                          <Link
                            to={`/movie-details/${item.slug}`}
                            className="relatedbox"
                          >
                            <img src={item.poster} alt="" class="w-100" />
                            <span>{item.name}</span>
                            {/* <p>{item.description}</p> */}
                          </Link>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default MovieDetailsModal;
