import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import SideBar from "../../UserDashboard/SideBar";
import ProfileSection from "../ProfileSection";
import { BeatLoader } from "react-spinners";
import * as API from "../../../API/Index";
const Event = ({ setIsLogin, data }) => {
  const [isLoder, setIsLoder] = useState(false);
  const [internalCount, setInternalCount] = useState(0);

  const [errorDu, setErrorDu] = useState("");

  const increment = (prev) => {
    setInternalCount((prev) => prev + 5);
    setErrorDu("");
  };
  const decrement = () => setInternalCount((prev) => Math.max(0, prev - 5));
  const displayCount = internalCount / 5;

  console.log("displayCount", displayCount);

  const formik = useFormik({
    initialValues: {
      channel_name: "",
      scheduled_time: "",
      thumbnail: null,
    },
    validationSchema: Yup.object({
      channel_name: Yup.string()
        .required("Channel Name is required")
        .max(50, "Channel Name cannot exceed 50 characters"),
      scheduled_time: Yup.date().required("Date is required"),
      thumbnail: Yup.mixed().required("Poster is required"),
    }),
    onSubmit: async (values) => {
      const data = {
        ...values,
        amount: internalCount,
        duration: displayCount,
        user_id: localStorage.getItem("_userId"),
      };
      console.log("data", data);
      setIsLoder(true);
      const header = localStorage.getItem("_tokenCode");
      if (displayCount === 0) {
        setErrorDu("Duration is required");
      } else {
        try {
          const response = await API.createSchedule(data, header);
          console.log("response", response);
          if (response.data.success === 1) {
            setIsLoder(false);
            window.open(response.data.url, "_blank", "noopener,noreferrer");
          }
        } catch (error) {}
      }

      // Simulate API call

      // setTimeout(() => {

      //   // const data = [...values, internalCount];
      //   // console.log("data", data);

      //   alert("Channel created successfully!");
      // }, 2000);
    },
  });

  return (
    <>
      <ProfileSection formData={data} />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="jj">
            <div className="tab_main editProfile">
              <div className="row justify-content-center g-0">
                <div className="col-sm-12 col-md-12 row">
                  <div className="col-md-3">
                    <SideBar formData={data} setIsLogin={setIsLogin} />
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content">
                      <h4 className="mb-3">Create Your Channel</h4>
                      <div className="row">
                        <div className="col-md-8">
                          <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="mb-3 form-group">
                              <label htmlFor="channelName">
                                Channel Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  formik.touched.channel_name &&
                                  formik.errors.channel_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="channel_name"
                                name="channel_name"
                                placeholder="Channel Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.channel_name}
                              />
                              {formik.touched.channel_name &&
                              formik.errors.channel_name ? (
                                <div className="invalid-feedback">
                                  {formik.errors.channel_name}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 form-group">
                              <label htmlFor="date">
                                Date <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                className={`form-control ${
                                  formik.touched.scheduled_time &&
                                  formik.errors.scheduled_time
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="scheduled_time"
                                name="scheduled_time"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.scheduled_time}
                              />
                              {formik.touched.scheduled_time &&
                              formik.errors.scheduled_time ? (
                                <div className="invalid-feedback">
                                  {formik.errors.scheduled_time}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 form-group d-none">
                              <label htmlFor="poster">
                                Poster <span className="text-danger">*</span>
                              </label>
                              <input
                                type="file"
                                className={`form-control ${
                                  formik.touched.thumbnail &&
                                  formik.errors.thumbnail
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="thumbnail"
                                name="thumbnail"
                                onChange={(event) =>
                                  formik.setFieldValue(
                                    "thumbnail",
                                    event.currentTarget.files[0]
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.thumbnail &&
                              formik.errors.thumbnail ? (
                                <div className="invalid-feedback">
                                  {formik.errors.thumbnail}
                                </div>
                              ) : null}
                            </div>
                            <button
                              type="submit"
                              className="btn btn-secondary2 mt-3"
                            >
                              Create
                            </button>
                          </form>
                        </div>
                        <div className="col-md-4">
                          <div className="items-center justify-center space-x-4 duration_box">
                            <h4>Duration</h4>
                            <p className="errorMess">{errorDu}</p>
                            <div className="align-items-center d-flex justify-content-between mt-4">
                              <button
                                className="btn btn-secondary2"
                                onClick={decrement}
                                disabled={internalCount === 0}
                              >
                                <i className="bi bi-dash-lg"></i>
                              </button>
                              <span className="text-5xl font-bold text-gray-800 durationCount">
                                {displayCount}
                              </span>
                              <button
                                onClick={increment}
                                className="btn btn-secondary2"
                              >
                                <i className="bi bi-plus-lg"></i>
                              </button>
                            </div>
                            <p className="mt-4 text-sm text-center text-gray-600">
                              Every hour, 5 dollars.
                            </p>
                            <p className="priceTotal">
                              Total : ${internalCount}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Event;
