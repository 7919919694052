import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CastSlider from "./MovieDetailsSlider/Cast";
import Crew from "./MovieDetailsSlider/Crew";
import SimilerPartSlider from "./MovieDetailsSlider/SimilerPartSlider";
import * as API from "../../API/Index.js";
import { IMG } from "../../API/constant.js";
import moment from "moment";
import { tailer } from "../../AppUtilities";
import { Rating } from "react-simple-star-rating";
import VideoPlayer from "../../components/Video/VideoPlayer.js";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { MESSAGE } from "../../schemas/Validation.js";
const initialValue = {
  comment: "",
};
const MovieDetails = ({ userData }) => {
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [formData, setFormData] = useState(initialValue);
  const [videoFormat, setVideoFormat] = useState("");
  const navigate = useNavigate();
  const [showMove, setShowMove] = useState(true);
  const dataurls = useParams();

  const [videoType, setVideoType] = useState(2);

  const [allData, setAllData] = useState([]);
  console.log("allData", allData);

  const individualMovie = async (data) => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const response = await API.getMovieDetils(
        dataurls.slug ? dataurls.slug : data
      );
      console.log("response", response);
      setAllData(response.data.data);
      const reqObj = {
        type: "trailer",
        id: response.data.data.id,
      };
      // console.log("reqObj", reqObj);
      const sresponse = await API.getMovieDetailsS3(reqObj, header);
      setVideoFormat(sresponse.data.fileUrl);
      // console.log("response", response);
    } catch (error) {}
  };

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
  };

  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);

  const handalerChanges = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const movieShow = async (data) => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        type: data === 1 ? "movie" : "trailer",
        id: allData.id,
      };
      //  console.log("reqObj", reqObj);
      const response = await API.getMovieDetailsS3(reqObj, header);
      setVideoFormat(response.data.fileUrl);
      console.log("response", response);
    } catch (error) {}
    if (localStorage.getItem("isLogin") === null) {
      navigate("/login");
    }
    setVideoType(data);
    setShowMove(!showMove);
  };

  const onRefreshPage = (data) => {
    individualMovie(data);
  };

  const commentSubmit = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        title: formData.title,
        comment: formData.comment,
        id: allData.id,
        user_id: localStorage.getItem("_userId"),
        rating: rating,
      };
      // console.log("reqObj", reqObj);
      const respnse = await API.commentDataAdd(reqObj, header);
      if (respnse.data.success === 1) {
        individualMovie();
        MESSAGE(respnse.data.message, 1);
        setOpen(false);
      }
      console.log("respnse", respnse);
    } catch (error) {}
  };

  const paymentCheckUser = () => {
    if (userData?.payment_status === false) {
      console.log("hello", userData);
      navigate("/support");
    } else {
      //navigate("/watch");
    }
  };

  useEffect(() => {
    individualMovie();
    window.scrollTo(0, 0);
  }, [dataurls.slug]);
  return (
    <>
      <section
        class="movie_details_Banner"
        style={{ backgroundImage: allData.poster }}
      >
        <div class="container-fluid p-0">
          {showMove ? (
            <div class="row justify-content-center">
              <div className="tailreMove p-0">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                  <video
                  autoplay
               
                  src=${videoFormat}
                  class=" aboutVideo"
                  />,
              `,
                  }}
                ></div>
                <div class="row justify-content-center">
                  <div class="col-sm-11">
                    <div className="movieDetails">
                      <h1 class="banner_heading mb-3">{allData.name}</h1>
                      <div class="clearfix"></div>
                      <span class="ratings">
                        <a href="#">
                          {allData.rating === null ? (
                            ""
                          ) : (
                            <i class="bi bi-star-fill"></i>
                          )}

                          {allData.rating === null
                            ? ""
                            : ` ${allData.rating}/5`}
                        </a>
                      </span>

                      <p class="site_text">
                        {allData.quality === undefined ||
                        allData.quality.length === 0
                          ? ""
                          : allData.quality.map((item, index) => (
                              <span>
                                {item.name}
                                {index < allData.quality.length - 1
                                  ? ", "
                                  : ""}{" "}
                              </span>
                            ))}
                      </p>
                      <p class="site_text">
                        {allData.language === undefined ||
                        allData.language.length === 0
                          ? ""
                          : allData.language.map((item, index) => (
                              <span>
                                {item.name}
                                {index < allData.language.length - 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))}
                      </p>
                      <ul class="caption_info d-flex align-items-center">
                        <li>
                          {allData.duration} <i class="bi bi-dot"></i>
                        </li>
                        <li>
                          {allData.genre === undefined ||
                          allData.genre.length === 0
                            ? ""
                            : allData.genre.map((item, index) => (
                                <span>
                                  {item.name}
                                  {index < allData.genre.length - 1
                                    ? ", "
                                    : ""}{" "}
                                </span>
                              ))}
                          <i class="bi bi-dot"></i>
                        </li>
                        <li>
                          {allData.certificates_short_name}
                          <i class="bi bi-dot"></i>
                        </li>
                        <li>
                          {moment(allData.release_date).format("DD MMMM, YYYY")}
                        </li>
                      </ul>
                      <div class="ratingBtn mt-3 mb-3">
                        <span>
                          Add your rating & review
                          <br />
                          Your ratings matter
                        </span>
                        <span onClick={onOpenModal}>
                          <Link to="#" class="btn btn-light">
                            Rate Now
                          </Link>
                        </span>
                      </div>
                      <div class="clearfix"></div>
                      <span>
                        {userData?.payment_status === false ? (
                          <span
                            class="transparent_btn banner_btn length_big"
                            onClick={paymentCheckUser}
                          >
                            <Link
                              className="text-light"
                              state={{
                                id: allData.id,
                                videoType: 1,
                              }}
                              to="#"
                            >
                              {" "}
                              Watch Movie
                            </Link>
                          </span>
                        ) : (
                          <span
                            class="transparent_btn banner_btn length_big"
                            onClick={paymentCheckUser}
                          >
                            <Link
                              className="text-light"
                              state={{
                                id: allData.id,
                                videoType: 1,
                              }}
                              to="/watch"
                            >
                              {" "}
                              Watch Movie
                            </Link>
                          </span>
                        )}

                        <span class="ms-3 transparent_btn banner_btn length_big">
                          <Link
                            className="text-light"
                            state={{
                              id: allData.id,
                              videoType: 2,
                            }}
                            to="/watch"
                          >
                            {" "}
                            Watch trailer
                          </Link>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row justify-content-center">
              <span className="crosebtn" onClick={movieShow}>
                <i class="bi bi-x-octagon-fill"></i>
              </span>
              <VideoPlayer
                videoFormat={videoFormat}
                videoType={videoType}
                allData={allData}
              />
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: `
                  <video
                  controls
                  src=${
                    videoType === 1
                      ? IMG + allData.movie_path
                      : IMG + allData.trailer_path
                  }
                  class="loaderVido aboutVideo"
                  />,
              `,
                }}
              ></div> */}
            </div>
          )}
        </div>
      </section>

      <section class="about_part">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-sm-11">
              <div dangerouslySetInnerHTML={{ __html: allData.description }} />
              {/* <h3 class="site_heading mb-4">About the movie</h3>
              <p>
                As the Avengers and their allies have continued to protect the
                world from threats too large for any one hero to handle, a new
                danger has emerged from the cosmic shadows: Thanos. A despot of
                intergalactic infamy, his goal is to collect all six Infinity
                Stones, artifacts of unimaginable power, and use them to inflict
                his twisted will on all of reality. Everything the Avengers have
                fought for has led up to this moment - the fate of Earth and
                existence itself has never been more uncertain.
              </p> */}
            </div>
          </div>
        </div>
      </section>

      <section class="cast">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-sm-11 d-flex flex-wrap align-items-center justify-content-between">
              <h3>Cast</h3>
              <span>
                <a href="#" class="text-light">
                  View all
                </a>
              </span>
              <hr class="mt-4 mb-4 w-100" />
            </div>
            <div class="col-sm-11">
              <CastSlider allData={allData} />
            </div>
          </div>
        </div>
      </section>

      <section class="cast crew mb-5">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-sm-11 d-flex flex-wrap align-items-center justify-content-between">
              <h3>Crew</h3>
              <span>
                <a href="#" class="text-light">
                  View all
                </a>
              </span>
              <hr class="mt-4 mb-4 w-100" />
            </div>
            <div class="col-sm-11">
              <Crew allData={allData} />
            </div>
          </div>
        </div>
      </section>

      <section class="top_week original likes pt-5 pb-5">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-sm-11 d-flex flex-wrap align-items-center justify-content-between mb-3">
              <h3>You might also like :</h3>
              <span>
                <a href="#" class="text-light">
                  View all
                </a>
              </span>
            </div>
          </div>
        </div>

        <div class="latest_release noBg">
          <div class="row justify-content-center">
            <div class="container-fluid">
              <div class="col-sm-11">
                <SimilerPartSlider onRefreshPage={onRefreshPage} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="reviws mt-5 mb-5">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-sm-11 d-flex flex-wrap align-items-center justify-content-between">
              <h3>Top reviews :</h3>
              <span>
                <a href="#" class="text-warning">
                  436.6K reviews
                </a>
              </span>
              <hr class="mt-4 mb-4 w-100" />
            </div>
            <div class="col-sm-11">
              <p class="site_text">Summary of 436.6K reviews.</p>
              <ul class="d-flex reviws_btn mb-5">
                <li>
                  <a href="#" class="btn btn-outline-warning">
                    #Blockbuster <span class="text-white">800</span>
                  </a>
                </li>
                <li>
                  <a href="#" class="btn btn-outline-warning">
                    #SuperDirection <span class="text-white">734</span>
                  </a>
                </li>
                <li>
                  <a href="#" class="btn btn-outline-warning">
                    #GreatActing <span class="text-white">706</span>
                  </a>
                </li>
                <li>
                  <a href="#" class="btn btn-outline-warning">
                    #AwesomeStory <span class="text-white">703</span>
                  </a>
                </li>
                <li>
                  <a href="#" class="btn btn-outline-warning">
                    #Wellmade <span class="text-white">600</span>
                  </a>
                </li>
              </ul>
              <div class="row align-items-center">
                <div class="col-12 col-md-9">
                  <div class="row">
                    <div class="col-12">
                      {allData.comments === undefined ||
                      allData.comments.length === 0
                        ? "hello"
                        : allData.comments.map((item, index) => (
                            <div class="col-sm-6 mb-3" key={index}>
                              <div class="single_reviws">
                                <div class="row align-items-center mb-4">
                                  <div class="col-6">
                                    <span>
                                      <img
                                        src="images/user.png"
                                        alt=""
                                        class="img-fluid"
                                      />{" "}
                                      {item.user_name}
                                    </span>
                                  </div>
                                  <div class="col-6 text-end">
                                    <span class="ratings">
                                      <a href="#">
                                        <i class="bi bi-star-fill"></i>{" "}
                                        {item.rating} /5
                                      </a>
                                    </span>
                                  </div>
                                </div>
                                <h5 class="mb-2">{item.title}</h5>
                                <p>{item.comment}</p>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3 text-end">
                  <span>
                    <a href="#" class="text-white">
                      View All <i class="bi bi-arrow-right-circle"></i>
                    </a>
                  </span>
                </div>
                <div class="col-12 mt-4">
                  <small class="tags">
                    Online tickets <i class="bi bi-arrow-right"></i> Movie
                    tickets <i class="bi bi-arrow-right"></i> Latest Movies{" "}
                    <i class="bi bi-arrow-right"></i> Avengers: Infinity War
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal open={open} onClose={onCloseModal} center>
        <h4 className="text-dark mb-4">Rating And Reviews</h4>
        <Rating
          onClick={handleRating}
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
          onPointerMove={onPointerMove}
          initialValue={allData.rating}
          showTooltip
          tooltipDefaultText="Rate this"
          tooltipArray={["Poor", "Fair", "Good", "Very Good", "Excellent"]}
        />
        <input
          className="form-control mb-3"
          placeholder="Title"
          onChange={handalerChanges}
          value={formData.title}
          name="title"
        />
        <textarea
          className="form-control messag"
          placeholder="Comment..."
          rows="5"
          cols="5"
          onChange={handalerChanges}
          value={formData.comment}
          name="comment"
        ></textarea>
        <button onClick={commentSubmit} className="btn btn-secondary2 mt-2">
          Submit
        </button>
      </Modal>
    </>
  );
};

export default MovieDetails;
