import React from "react";
import { donation } from "../AppUtilities";
import SliderPic from "../assets/images/about/9.png";

const Donations = () => {
  return (
    <>
      <section className="mt-8  donations">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5 text-center">
              <img src={donation} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-6">
              <h6>Imboxo Live Pass – One Pass, One Stream:</h6>
              <h1>Unlock Your Live Streaming Potential for Just $5!</h1>
              <p>
                Are you a movie maker or content creator eager to captivate your
                audience with live streaming? The Imboxo Live Passis your
                perfect solution! For just $5, you can create a channel, live
                stream to your audience, and invite them with a simple
                invitation link. Each pass gives you the power to host one live
                stream—making it ideal for creators who value flexibility and
                control.
                <br />
                <br /> Want to host more streams? Simply purchase additional
                passes! With Imboxo Live Pass, you’re in complete charge of when
                and how often you go live.
              </p>
              <span>
                <a href="#" target="_blank" class="btn btn-secondary2 mb-4">
                  Donate Now
                </a>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="p-v-60 text-center">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12">
              <h3 className="mb-4">How It Works</h3>
              <p>
                For every additional stream, you’ll need to buy a new pass.
                Whether you’re hosting weekly Q&As, premiering your latest
                movie, or sharing exclusive content, Imboxo Live Pass ensures
                you only pay for what you use.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="donationsBox">
                <span>
                  <i class="fa-solid fa-ticket"></i>
                </span>
                <h5>Buy a Pass</h5>
                <p>
                  Each Imboxo Live Pass gives you access to one live stream.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="donationsBox">
                <span>
                  <i class="fa-solid fa-film"></i>
                </span>
                <h5>Create Your Channel</h5>
                <p>Set up your channel in minutes and prepare to go live.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="donationsBox">
                <span>
                  <i class="fa-solid fa-user"></i>
                </span>
                <h5>Invite Your Audience</h5>
                <p>Share your private invitation link with your viewers.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="donationsBox">
                <span>
                  <i class="fa-solid fa-satellite-dish"></i>
                </span>
                <h5>Go Live! </h5>
                <p>Stream your content and engage with your audience. .</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="howMovie">
        <div className="container-fluid p-0">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={SliderPic} alt="" className="w-100" />
            </div>
            <div className="col-md-6 p-5">
              <h3 className="mb-4">
                How Movie Makers and Content Creators Can Utilize Imboxo Live
                Pass
              </h3>
              <ul class=" mb-4">
                <li>
                  <span>
                    <i class="fa-solid fa-film"></i>
                  </span>
                  <div>
                    <h5>Host Exclusive Premieres</h5>
                    <p>
                      Unveil your movie or project to a curated audience. Your
                      live stream becomes a private, intimate experience—perfect
                      for creating a buzz.
                    </p>
                  </div>
                </li>
                <li>
                  <span>
                    <i class="fa-solid fa-certificate"></i>
                  </span>
                  <div>
                    <h5>Offer Behind-the-Scenes Content</h5>
                    <p>
                      Invite your fans to witness the creative process. Share
                      bloopers, sneak peeks, and more with a single, memorable
                      live stream.
                    </p>
                  </div>
                </li>
                <li>
                  <span>
                    <i class="fa-regular fa-circle-question"></i>
                  </span>
                  <div>
                    <h5>Conduct Live Q&A Sessions</h5>
                    <p>
                      Answer your audience’s burning questions and build
                      stronger connections in real time.
                    </p>
                  </div>
                </li>
                <li>
                  <span>
                    <i class="fa-solid fa-user"></i>
                  </span>
                  <div>
                    <h5>Collaborate with Fellow Creators</h5>
                    <p>
                      Team up with other creators to co-host engaging live
                      sessions, maximizing your reach and impact.
                    </p>
                  </div>
                </li>
                <li>
                  <span>
                    <i class="fa-solid fa-film"></i>
                  </span>
                  <div>
                    <h5>Showcase Trailers and Teasers</h5>
                    <p>
                      Generate excitement for your upcoming projects by
                      streaming your latest trailers or teasers to your most
                      dedicated fans.
                    </p>
                  </div>
                </li>
                <li>
                  <span>
                    <i class="fa-solid fa-users"></i>
                  </span>
                  <div>
                    <h5>Engage with Niche Audiences</h5>
                    <p>
                      Create targeted live streams tailored to niche groups that
                      align with your content or creative vision.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="p-v-60 text-center">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12">
              <h3 className="mb-4">Why Choose Imboxo Live Pass?</h3>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="donationsBox">
                <span>
                  <i class="fa-regular fa-circle-check"></i>
                </span>
                <h5>Pay-as-You-Go</h5>
                <p>
                  Only pay for the streams you host—no recurring fees or unused
                  minutes.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="donationsBox">
                <span>
                  <i class="fa-regular fa-circle-check"></i>
                </span>
                <h5>Unlimited Minutes</h5>
                <p>
                  Each pass gives you unlimited streaming time for a single
                  session.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="donationsBox">
                <span>
                  <i class="fa-regular fa-circle-check"></i>
                </span>
                <h5>Private Invitations</h5>
                <p>
                  Invite your audience via a private link for a secure and
                  exclusive experience.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="donationsBox">
                <span>
                  <i class="fa-regular fa-circle-check"></i>
                </span>
                <h5>Effortless Setup</h5>
                <p>Create your channel and go live in just a few clicks.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="howMovie pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h4 className="mb-4">Get Started Today!</h4>
              <p>
                For just $5, you can start your journey to live streaming
                success. Whether it’s your first stream or your tenth, the
                Imboxo Live Pass gives you the flexibility to create, connect,
                and inspire.
              </p>
            </div>
            <div className="col-md-6">
              <h4 className="mb-4">How to Purchase Your Imboxo Live Pass</h4>
              <ul class=" mb-4">
                <li>Click the button below to make your purchase.</li>
                <li>Set up your channel and get ready to go live.</li>
                <li>
                  For additional streams, purchase more passes anytime you need
                  them.
                </li>
              </ul>
            </div>
            <div className="col-md-12 mt-4">
              <h3 className="mb-4">Buy Now & Create Your Channel</h3>
              <p>
                One Pass. One Stream. Unlimited Potential. Let your creativity
                shine with the <strong>Imboxo Live Pass</strong>—because your
                story deserves to be told!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Donations;
