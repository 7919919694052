import React from "react";
import MovieListSlide from "./MovieListSlide";

const TopMovie = ({ genre, movies, movieWishListAdd }) => {
  return (
    <>
      <section className="newreleasePart slide1 mt-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-sm-12 mb-1">
              <div class="bannerList m-0">
                <h3 class="site_heading">
                  {genre} <span>Movies</span>
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="latest_release noBg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <MovieListSlide
                  movieWishListAdd={movieWishListAdd}
                  movies={movies}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="top_week pt-5 moviesListCarosals">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-11 d-flex align-items-center justify-content-between">
              <h4></h4>
            </div>
            <div class="col-11"></div>
          </div>
        </div>
      </section> */}
    </>
  );
};
export default TopMovie;
