import React, { useEffect, useRef, useState } from "react";
import Control from "./Control";
import ReactPlayer from "react-player";
import { Container } from "@material-ui/core";
import { formatTime } from "../../AppUtilities";
import { IMG } from "../../API/constant";
import { useLocation } from "react-router";
let count = 0;
const VideoPlayer = ({ videoType, allData, videoFormat }) => {
  //console.log("videoType", videoType);
  const [playedSeconds, setPlayedSeconds] = useState(0); // Last played position
  //const playerRef = useRef(null);
  const videoPlayerRef = useRef(null);
  const controlRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [videoSpped, setVideoSpped] = useState("1.0");
  //const [played, setPlayed] = useState(0);
  const location = useLocation();

  const [videoState, setVideoState] = useState({
    playing: true,
    muted: location.pathname === "/home" ? true : false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
  });

  const videoPlayerSpeed = () => {
    setIsOpen(!isOpen);
  };

  const speedValue = (data) => {
    setVideoSpped(data);
    setIsOpen(false);
  };

  //Destructuring the properties from the videoState
  const { playing, muted, volume, playbackRate, played, seeking, buffer } =
    videoState;

  const playPauseHandler = () => {
    //plays and pause the video (toggling)
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const rewindHandler = () => {
    //Rewinds the video player reducing 5
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 5);
  };

  const handleFastFoward = () => {
    //FastFowards the video player by adding 10
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10);
  };

  //console.log("========", (controlRef.current.style.visibility = "false"));
  const handleReady = () => {
    // Seek to the last played time if it exists
    if (playedSeconds > 0 && videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(playedSeconds, "seconds");
    }
  };
  const progressHandler = (state) => {
    localStorage.setItem("lastPlayedTime", state.playedSeconds);
    //console.log("state", state);
    if (location.pathname === "/watch") {
      if (count > 3) {
        // setVideoState({ ...videoState, playing: false });
        controlRef.current.style.visibility = "hidden"; // toggling player control container
      } else if (controlRef.current.style.visibility === "visible") {
        count += 1;
      }

      if (!seeking) {
        setVideoState({ ...videoState, ...state });
      }
    } else {
      if (count > 3) {
        // setVideoState({ ...videoState, playing: false });
        controlRef.current.style.visibility = "visible"; // toggling player control container
      } else if (controlRef.current.style.visibility === "visible") {
        count += 1;
      }
    }
  };

  const seekHandler = (e, value) => {
    setVideoState({ ...videoState, played: parseFloat(value / 100) });
    videoPlayerRef.current.seekTo(parseFloat(value / 100));
  };

  const seekMouseUpHandler = (e, value) => {
    console.log(value);

    setVideoState({ ...videoState, seeking: false });
    videoPlayerRef.current.seekTo(value / 100);
  };

  const volumeChangeHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: Number(newVolume) === 0 ? true : false, // volume === 0 then muted
    });
  };

  const volumeSeekUpHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: newVolume === 0 ? true : false,
    });
  };

  const muteHandler = () => {
    //Mutes the video player
    setVideoState({ ...videoState, muted: !videoState.muted });
  };

  const onSeekMouseDownHandler = (e) => {
    setVideoState({ ...videoState, seeking: true });
  };

  const mouseMoveHandler = () => {
    controlRef.current.style.visibility = "visible";
    count = 0;
  };

  const bufferStartHandler = () => {
    console.log("Bufering.......");
    setVideoState({ ...videoState, buffer: true });
  };

  const bufferEndHandler = () => {
    console.log("buffering stoped ,,,,,,play");
    setVideoState({ ...videoState, buffer: false });
  };

  const currentTime = videoPlayerRef.current
    ? videoPlayerRef.current.getCurrentTime()
    : "00:00";

  const duration = videoPlayerRef.current
    ? videoPlayerRef.current.getDuration()
    : "00:00";

  //console.log("duration", duration);

  function convertSecondsToHMS(seconds) {
    // Calculate the number of hours
    const hours = Math.floor(seconds / 3600);

    // Calculate the remaining seconds after converting to hours
    const remainingSecondsAfterHours = seconds % 3600;

    // Calculate the number of minutes
    const minutes = Math.floor(remainingSecondsAfterHours / 60);

    // Calculate the remaining seconds after converting to minutes
    const remainingSeconds = remainingSecondsAfterHours % 60;

    return {
      hours: hours,
      minutes: minutes,
      seconds: remainingSeconds,
    };
  }

  const hms = convertSecondsToHMS(duration);

  const formatCurrentTime = formatTime(currentTime);
  const formatDuration = hms;

  const enablePiP = () => {
    if (document.pictureInPictureEnabled && videoPlayerRef.current) {
      const videoElement = videoPlayerRef.current.getInternalPlayer();
      if (videoElement) {
        videoElement
          .requestPictureInPicture()
          .then(() => {
            console.log("PiP enabled");
          })
          .catch((error) => {
            console.error("PiP error:", error);
          });
      }
    }
  };

  const toggleFullscreen = () => {
    const playerElement = videoPlayerRef.current.getInternalPlayer();

    if (playerElement && playerElement.requestFullscreen) {
      playerElement.requestFullscreen();
    } else if (playerElement && playerElement.webkitEnterFullscreen) {
      // for Safari
      playerElement.webkitEnterFullscreen();
    } else if (
      videoPlayerRef.current.wrapper &&
      videoPlayerRef.current.wrapper.requestFullscreen
    ) {
      videoPlayerRef.current.wrapper.requestFullscreen();
    } else if (
      videoPlayerRef.current.wrapper &&
      videoPlayerRef.current.wrapper.webkitRequestFullscreen
    ) {
      // for Safari
      videoPlayerRef.current.wrapper.webkitRequestFullscreen();
    } else if (
      videoPlayerRef.current.wrapper &&
      videoPlayerRef.current.wrapper.msRequestFullscreen
    ) {
      // for IE11
      videoPlayerRef.current.wrapper.msRequestFullscreen();
    }
  };

  const disablePiP = () => {
    if (document.pictureInPictureElement) {
      document
        .exitPictureInPicture()
        .then(() => {
          console.log("PiP disabled");
        })
        .catch((error) => {
          console.error("Error exiting PiP:", error);
        });
    }
  };
  const handleVideoEnd = () => {
    setVideoState({ ...videoState, playing: false });
  };
  const handleProgress = (state) => {
    //setPlayed(state.played);
  };

  useEffect(() => {
    // Retrieve the last played time from localStorage
    const savedTime = localStorage.getItem("lastPlayedTime");
    if (savedTime) {
      setPlayedSeconds(parseFloat(savedTime));
    }
  }, []);
  return (
    <>
      <div className="video_container" style={{ justifyContent: "start" }}>
        <Container maxWidth="md" justify="center">
          <div className="player__wrapper" onMouseMove={mouseMoveHandler}>
            <ReactPlayer
              ref={videoPlayerRef}
              className="player"
              url={videoType === 1 ? videoFormat : videoFormat}
              width="100%"
              height="100%"
              playing={playing}
              volume={volume}
              muted={muted}
              onProgress={progressHandler}
              onBuffer={bufferStartHandler}
              onBufferEnd={bufferEndHandler}
              playbackRate={videoSpped}
              onEnded={handleVideoEnd}
              loop={true}
              onReady={handleReady}
              // onDuration={(v) => console.log("vfsf", v)}
              // controls={true}
              //pip={true}
              //stopOnUnmount={false}
              // onEnablePIP={true}
            />

            <Control
              videoPlayerSpeed={videoPlayerSpeed}
              videoSpped={videoSpped}
              controlRef={controlRef}
              onPlayPause={playPauseHandler}
              playing={playing}
              onRewind={rewindHandler}
              onForward={handleFastFoward}
              played={played}
              onSeek={seekHandler}
              onSeekMouseUp={seekMouseUpHandler}
              volume={volume}
              onVolumeChangeHandler={volumeChangeHandler}
              onVolumeSeekUp={volumeSeekUpHandler}
              mute={muted}
              onMute={muteHandler}
              playRate={playbackRate}
              duration={formatDuration}
              watchTime={currentTime}
              currentTime={formatCurrentTime}
              onMouseSeekDown={onSeekMouseDownHandler}
              enablePiP={enablePiP}
              isOpen={isOpen}
              speedValue={speedValue}
              buffer={buffer}
              toggleFullscreen={toggleFullscreen}
            />
          </div>
          {/* <button onClick={enablePiP}>Enable PiP</button> */}
          {/* <button onClick={disablePiP}>Disable PiP</button> */}
        </Container>
      </div>
    </>
  );
};

export default VideoPlayer;
