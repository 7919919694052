import React from "react";

const UserInfo = () => {
  return (
    <>
      <section className=" userInfo">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7">
              <div className="userInfobox">
                <h4 class="mb-4">User Information </h4>
                <hr />
                <div className="row">
                  <div class="mb-3 col-sm-6">
                    <label>First Name </label>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      class="form-control"
                      name=""
                    />
                  </div>
                  <div class="mb-3 col-sm-6">
                    <label>Last Name </label>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      class="form-control"
                      name=""
                    />
                  </div>
                  <div class="mb-3 col-sm-6">
                    <label>Email </label>
                    <input
                      type="email"
                      placeholder="Enter Your Email"
                      class="form-control"
                      name=""
                    />
                  </div>
                  <div class="mb-3 col-sm-6">
                    <label>Phone Number </label>
                    <input
                      type="text"
                      placeholder="Enter Your Phone Number"
                      class="form-control"
                      name=""
                    />
                  </div>
                  <div class="col-sm-12">
                    {/* <label for="">Introductory Video</label> */}
                    <div class="file-drop-area mt-2 mb-3">
                      <span class="choose-file-button">Choose files</span>
                      <span class="file-message">
                        or drag and drop files here
                      </span>
                      <input class="file-input" type="file" multiple />
                    </div>
                  </div>
                  <div class="mt-3 text-center col-12">
                    <button type="button" class="btn btn btn-secondary2">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserInfo;
